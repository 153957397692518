import React from 'react';
import HomeTableWrap from "./style/wrap";
import Container from "../../../../components/paper/container";
import SectionTitle from "../../../../components/heading/section";
import TableContent from "./style/table";

const AriannaTable = () => {
    return (
        <HomeTableWrap>
            <Container>
                <SectionTitle>
                    Arianna's Mother Has Taken Me To Court 369 Times To Coverup Sexual Abuse
                </SectionTitle>
                <TableContent>
                    {/* <div>
                        <div>
                            <div>06/13/15</div>
                        </div>
                        <div colSpan={11}></div>
                        <div></div>
                        <div>
                            <div>The last day of our family visit to Toronto. We had to be at the airport by 12 pm for our flight back to San Francisco. Reshma Kumar put Arianna to bed around 8:30 am. I went to check on Arianna at around 9:00/9:15 am. As I approached the room, the door was slightly open. When I opened the door, Ganraj Kumar (Perpetrator) was standing over Arianna. Her diapers were undone, he was touching her vagina with one hand and masturbating with the other hand. My heart was racing. I was shaking, sweating and angry. I said to him, “What are you doing!”. He calmly, put his penis in his pajamas, looked me in the eye, with a piercing dark stare, said nothing to me and quietly walked away. I was very nervous. I didn’t know what to do. I looked at Arianna’s vagina area, I was shaking, I could not tell if anything was damaged. I gently fastened her diaper loosely and covered her with her blanket that was in shambles. I went downstairs right away and told Reshma Kumar what I had just witnessed. Reshma Kumar was totally calm about it. I told her I was going to the police to report Ganraj Kumar (Perpetrator) for sexually abusing Arianna. Reshma Kumar was dismissive and told me to “just forget about it”, saying she did not want to hear about it anymore. She did not even go to check on Arianna. I told her we have to take Arianna to a doctor. She silenced me and minimized my concern and fear, saying that, “I was blowing things out of proportion.” VK arrived at around 9:40 am to take us to the airport. I told VK what I had just witnessed. He said, that it was a family matter and there was no need to involve the police.</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/27/2015</div>
                        </div>
                        <div></div>
                        <div></div>
                        <div>
                            <div>Reshma Kumar hired <a href="criminal-kevinboileau">Kevin Boileau</a>, who claimed to be a lawyer and marriage therapist charging $1000 an hour. I later learned that he was disbarred in the State of Washington for <a href="http://localhost:3000/pdf/kevinboileau1.pdf">raping a female</a> client, <a href="http://localhost:3000/pdf/kevinboileau4.pdf">blackmailing her</a> to keep quiet and other <a href="https://courtwatch.live/pdf/kevinboileau3.pdf">extortion schemes</a>. He was unethical, pushy and aggressive. He always talked about money. His goal was to rack up billings. He wanted me to forget witnessing the sexual abuse of our daughter. He was the architect of the sexual abuse cover up. He introduced Nathalie Ferro to Reshma Kumar. Then Nathalie Ferro contacted Santa Clara DA Kathrina Ohde and they contacted Judge Cynthia Lie (Exhibit 6). Collectively they colluded to concocted a scheme to cover up the sexual abuse of my daughter using a DVRO. Then Nathalie Ferro and Katherina Ohde contacted corrupt San Jose detective Joe Perez and fabricated false DVRO violation charges which Judge Lie used as cover to the make the temp DVRO, permanent (Exhibit 7). There wasn’t a shred of evidence. It was all completely fabricated allegations, theater and drama used to commit fraud upon the court. All the proof of this is in black and white in the redacted billing statements that can be seen as clear as day. The mountain of evidence that exposes this fraud is too much to include here.</div>
                        </div>
                    </div> */}
                    <div>
                        <div>
                            <div>11/17/15</div>
                        </div>
                        <div>
                            <div>Declaration: Under UCCJEA</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Legacy Name = FL Decl:Uniform Custody Act Decision = M Closed = 11/17/2015</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/17/15</div>
                        </div>
                        <div>
                            <div>Declaration: Income and Expense</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Legacy Name = FL Income/Expense Decl Decision = M Closed = 11/17/2015</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/17/15</div>
                        </div>
                        <div>
                            <div>Petition: Dissolution w/minor</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Legacy Name = FL Petn:Dissol of Marriage w/Minor Decision = M Closed = 11/17/2015</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/17/15</div>
                        </div>
                        <div>
                            <div>Summons: Issued/Filed</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Legacy Name = FL Summons Decision = M Closed = 11/17/2015</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/17/15</div>
                        </div>
                        <div>
                            <div>Declaration: Residence</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Legacy Name = FL Decl of Residence Decision = M Closed = 11/17/2015</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/17/15</div>
                        </div>
                        <div>
                            <div>Bulk Scanned Documents</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>BULK SCAN 11-17-2015 to 12-02-2015</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/19/15</div>
                        </div>
                        <div>
                            <div>Proof of Service of Summons Filed</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Text = petn, summ, blnk response, uccjea..; 11/18/15 @ 1139am Legacy Name = FL Proof of
                                Svc/Summons Decision = M Closed = 11/19/2015
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/11/15</div>
                        </div>
                        <div>
                            <div>Subsequent Request: DVRO w/ Minor</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/14/15</div>
                        </div>
                        <div>
                            <div>Miscellaneous: Converted Document</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Pretrial Service Report</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/15/15</div>
                        </div>
                        <div>
                            <div>Fee Waiver Application</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/15/15</div>
                        </div>
                        <div>
                            <div>Response: Dissolution</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/15/15</div>
                        </div>
                        <div>
                            <div>Uniform Child Custody Jurisdiction Enforcement Act</div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/15/15</div>
                        </div>
                        <div>
                            <div>Proof of Service: Personal</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>dv109, 110, 120, 110, 105, fl140, 145, 108, 113 (daya baran)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/18/15</div>
                        </div>
                        <div>
                            <div>Fee Waiver Order-Deny</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>(r)pay $435 resp on or before 1/1/16-lal</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/21/15</div>
                        </div>
                        <div>
                            <div>Miscellaneous:Converted Document</div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>Pretrial Service Report</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/21/15</div>
                        </div>
                        <div>
                            <div>Subsequent Request: DVRO w/ Minor</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/30/15</div>
                        </div>
                        <div>
                            <div>Declaration: Income and Expense</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/04/16</div>
                        </div>
                        <div>
                            <div>Order: Temporary Restraining Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>AMENDED DV110</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/04/16</div>
                        </div>
                        <div>
                            <div>Response: Request for Domestic Violence Restraining Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/04/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/04/16</div>
                        </div>
                        <div>
                            <div>Change of Address Pro Per</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/04/16</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Personal
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>dv109,
                                dv110, dv100, etc
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/04/16</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Personal
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>fl120,
                                fl311
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/05/16</div>
                        </div>
                        <div>
                            <div>Miscellaneous:
                                Converted Document
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Kumar
                                and Baran 11-17-2015 to12-21-2015.pdf
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/06/16</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/06/16</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Income and Expense
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/06/16</div>
                        </div>
                        <div>
                            <div>DV
                                Continuance
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>w/
                                modified tro
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/08/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Minutes
                                Non-Criminal
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/25/16</div>
                        </div>
                        <div>
                            <div>Order:
                                Restraining Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/26/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Minutes
                                Non-Criminal
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/01/16</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: TRO (Mod. incl Custody and/or Visitation)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(p)
                                OST Granted; TECO SWC; Abduction order SWC; visit, teco -ar
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/01/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>in
                                supp of exparte req for mod of visit
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/01/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>of
                                dr lori bisbey in supp of exparte mtn to..
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/18/16</div>
                        </div>
                        <div>
                            <div>Miscellaneous:
                                Converted Document
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Copy
                                of FCS JCC Referral Atty
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/18/16</div>
                        </div>
                        <div>
                            <div>Miscellaneous:
                                Converted Document
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Copy
                                of FCS JCC Referral Atty
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/18/16</div>
                        </div>
                        <div>
                            <div>Miscellaneous:
                                Converted Document
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Copy
                                of FCS JCC Referral Atty
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/18/16</div>
                        </div>
                        <div>
                            <div>Miscellaneous:
                                Converted Document
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Copy
                                of FCS JCC MEMO 2 4 16
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/22/16</div>
                        </div>
                        <div>
                            <div>Miscellaneous:
                                Converted Document
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Mediation
                                DV Questionaire - Petitioner 2 4 16
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/22/16</div>
                        </div>
                        <div>
                            <div>Miscellaneous:
                                Converted Document
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Mediation
                                DV Asessment 2 4 16
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/22/16</div>
                        </div>
                        <div>
                            <div>Miscellaneous:
                                Converted Document
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Mediation
                                Notes 2 4 16
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/24/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Minutes
                                Non-Criminal
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/24/16</div>
                        </div>
                        <div>
                            <div>Stipulation
                                and Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>continuance
                                2/24/16 to 3/1/16
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/25/16</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>03/01/16</div>
                        </div>
                        <div>
                            <div>Stipulation
                                &amp; Order - No Fee
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>re:
                                continuance
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>03/17/16</div>
                        </div>
                        <div>
                            <div>Notice:
                                Change Address/Firm Name
                            </div>
                        </div>
                        <div>
                            <div>Debra
                                Slone,
                            </div>
                        </div>
                        <div>
                            <div>w/pos</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/07/16</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: TRO (Mod. incl Custody and/or Visitation)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>ost
                                granted, teco denied pending hrg
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/13/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>Minutes
                                Non-Criminal
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/27/16</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: Other (Fee Applies)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(P)
                                other: see #8
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/27/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>of
                                Reshma S Kumar in supp of motion to bifurcate
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/23/16</div>
                        </div>
                        <div>
                            <div>Order:
                                Restraining Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar, Daya Baran,</div>
                        </div>
                        <div>
                            <div>amended,
                                of 1/25/16 hrg
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/23/16</div>
                        </div>
                        <div>
                            <div>Order:
                                Restraining Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar, Daya Baran,</div>
                        </div>
                        <div>
                            <div>of
                                hrg 4/13/16
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/23/16</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>5/31/16
                                @ 9am d81
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/27/16</div>
                        </div>
                        <div>
                            <div>JCC
                                Statement
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/27/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>re
                                issues to be addressed at the jcc
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/27/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>in
                                supp of mtn to shorten time for srvc and hrg
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/27/16</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: Attorney Fees
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(p)
                                other: see #8
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/27/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>in
                                supp of mtn to allow travel w/ child, compeling financial
                                disclosures, atty fees and sanctions
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/31/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Minutes
                                Non-Criminal
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/31/16</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order, FL320
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/31/16</div>
                        </div>
                        <div>
                            <div>Judgment:
                                Status Only
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/31/16</div>
                        </div>
                        <div>
                            <div>Notice:
                                Entry of Judgment w/POS
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/02/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Minutes
                                Non-Criminal
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/02/16</div>
                        </div>
                        <div>
                            <div>Notice:
                                Association/Co-Counsel
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/09/16</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>of
                                hrg 6/2/16
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/05/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>Minutes
                                Non-Criminal
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/27/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>of
                                petnr in supp of mtn suspending visit, requesting permission to
                                travel with child..
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/27/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>of
                                p-atty in supp of mtn for atty's f/c
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/27/16</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: TRO (Mod. incl Custody and/or Visitation)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>ost
                                granted, teco swc; visit, atty f/c, teco, other: see #8
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/29/16</div>
                        </div>
                        <div>
                            <div>Request
                                for Order (Initial)
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>(r)
                                ost granted, set per judge, Initial Spousal Support, atty fees,
                                other: release cp $ / std ro viol / change evaluator
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/04/16</div>
                        </div>
                        <div>
                            <div>Notice:
                                Appeal
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/04/16</div>
                        </div>
                        <div>
                            <div>Fee
                                Waiver Application
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/09/16</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar, Daya Baran,</div>
                        </div>
                        <div>
                            <div>response
                                to various of her filings
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/13/16</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>substitution
                                of Attorney Debra L. Slone
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/14/16</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: TRO
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>ost
                                granted/teco granted/mod/visitation/see#8/
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/14/16</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/14/16</div>
                        </div>
                        <div>
                            <div>Fee
                                Waiver Application
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/16/16</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Income and Expense
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/16/16</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: TRO (Mod. incl Custody and/or Visitation)
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>(r)
                                ost granted/teco granted in part; set for 10/05/16, 1:30pm; prop
                                contrl, visit, s/s
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/23/16</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Substitution
                                of Attorney
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/27/16</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Substitution
                                of Attorney OUT
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/27/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>STATEMENT
                                OF HOOVER KREPELKA
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/27/16</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>8/10/16</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/29/16</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Supplemental
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Supplemental
                                Declaration
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/04/16</div>
                        </div>
                        <div>
                            <div>Notice</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Notice
                                of Association of Counsel
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/04/16</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>RFO;
                                TECO; Dec of Reshma Kumar; Supp Dec of Reshma Kumar
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/06/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/17/16</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/17/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>P's
                                Dec in Opposition to Exparte Motion by Resondent; Hearing 10/20/16
                                1:30p
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/17/16</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Income and Expense
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Income
                                and Expense Declaration
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/20/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/21/16</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>10/20/16</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/01/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>DEC
                                of Reshma S. Kumar In Re Review
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/01/16</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>DEC
                                of Reshma S. Kumar In Re Review
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/02/16</div>
                        </div>
                        <div>
                            <div>Order:
                                Emergency Screening
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/02/16</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/07/16</div>
                        </div>
                        <div>
                            <div>Declaration:
                                In Support
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Dec
                                of Petitioner in Support of Renewing &amp; Modifing DVRO &amp;
                                Extending
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/07/16</div>
                        </div>
                        <div>
                            <div>Declaration:
                                In Support
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Dec
                                of P's in Support of Renewing &amp; Modifing DVRO &amp; Extending
                                its protection
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/10/16</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>10/20/16</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/10/16</div>
                        </div>
                        <div>
                            <div>Order:
                                Wage and Earning Assignment for Spousal Support
                            </div>
                        </div>
                        <div>
                            <div>Reshma
                                Kumar
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/15/16</div>
                        </div>
                        <div>
                            <div>Request:
                                Renew Restraining Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar, Daya Baran,</div>
                        </div>
                        <div>
                            <div>all
                                granted
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/09/16</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>superseded
                                by 11/2/16 EMS order
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/09/16</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: Other (Fee Applies)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Vocational
                                Assessment Employment Efforts Order Request; modify DVRO
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/09/16</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Declaration
                                of Reshma Kumar In Support of RFO
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/09/16</div>
                        </div>
                        <div>
                            <div>Memorandum:
                                Points and Authorities
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Memorandum
                                of Points and Authorities in Support of RFO
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/14/16</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Personal
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>RFO;
                                DEC of PTR in Supp. of RFO; P&amp;A in Supp. of RFO; HRG on
                                1/24/2017 1:30p
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/16/16</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Substitution
                                of Attorney
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/22/16</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>10/20/16
                                hrg, amended, s/s, property
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/10/17</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Substitution
                                of Attorney
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/10/17</div>
                        </div>
                        <div>
                            <div>Notice:
                                Limited Scope Representation
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Notice:
                                Limited Scope Representation
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/11/17</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Responsive
                                Dec to RFO 1/24/17 Hrg
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/11/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>POS
                                Resp Dec to RFO on OC
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/12/17</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: Attorney Fees
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>RFO
                                Enforcement, Fees &amp; Costs
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/12/17</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Dec
                                of Atty ISO RFO RE Atty Fees
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/12/17</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Income and Expense
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Income
                                and Expense Declaration
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/12/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>EAO
                                filed 11/10/16 on Attys &amp; Employer
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/12/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/19/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>POS
                                Resp Dec, RFO, I&amp;E, Atty Dec on OC
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/19/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Personal
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/23/17</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Substitution
                                of Attorney
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/24/17</div>
                        </div>
                        <div>
                            <div>Order:
                                Wage and Earning Assignment for Spousal Support
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/24/17</div>
                        </div>
                        <div>
                            <div>Order:
                                Restraining Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/24/17</div>
                        </div>
                        <div>
                            <div>Order:
                                Seek Work
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/24/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/01/17</div>
                        </div>
                        <div>
                            <div>Remittitur</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>RESPONDENT
                                TO RECOVER COSTS.
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/10/17</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Substitution
                                of Attorney
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/14/17</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>hrg
                                2/23/17
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/14/17</div>
                        </div>
                        <div>
                            <div>Declaration:
                                In Opposition
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Declaration:
                                In Opposition of RFO
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/14/17</div>
                        </div>
                        <div>
                            <div>Declaration:
                                In Support
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Declaration:
                                In Support of Request Atty Fees 271
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/14/17</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Income and Expense
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Income
                                and Expense Declaration
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/14/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>responsive
                                dec to rfo, etc
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/23/17</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Atttached:
                                Seek Work Order
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/23/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>03/20/17</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>2/23/17</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/04/17</div>
                        </div>
                        <div>
                            <div>DV
                                Continuance
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>DV116,
                                c/f 04-04-17. DV140,150.
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/04/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/20/17</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: Attorney Fees
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Request
                                for Order re Motion to Compel
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/20/17</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Attorney
                                Declaration in Support of Atty Fees
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/20/17</div>
                        </div>
                        <div>
                            <div>Statement</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Separate
                                Statement in Support of Motion to Compel
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/27/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/03/17</div>
                        </div>
                        <div>
                            <div>DV
                                Continuance
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>DV
                                116 c/f 5/8/17
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/09/17</div>
                        </div>
                        <div>
                            <div>Fee
                                Waiver Application
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>fcs
                                fees
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/09/17</div>
                        </div>
                        <div>
                            <div>Fee
                                Waiver Order-Grant-Subsequent
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>fcs
                                fees
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/10/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>RFO;
                                PTR STMT in Supp. of MTN; DEC in Supp. of A/F; HRG 5/24/17 1:30p
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/16/17</div>
                        </div>
                        <div>
                            <div>Memorandum:
                                Points and Authorities
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Supplemental
                                Memorandum: Points and Authorities in Support
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/16/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Supplemental
                                Memorandum: Points and Authorities in Support
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/22/17</div>
                        </div>
                        <div>
                            <div>DV
                                Continuance
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(p)
                                c/f 5/22/17 per dv116
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/22/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/23/17</div>
                        </div>
                        <div>
                            <div>DV
                                Continuance
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>c/f
                                06-23-17 per dv116
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/08/17</div>
                        </div>
                        <div>
                            <div>Notice:
                                Case Status
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/08/17</div>
                        </div>
                        <div>
                            <div>Notice:
                                Case Status
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/08/17</div>
                        </div>
                        <div>
                            <div>Notice:
                                Case Status
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/10/17</div>
                        </div>
                        <div>
                            <div>Response/Reply</div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Response
                                (r)
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/10/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Proof
                                of Service reponse to motion (r)
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/17/17</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Supplemental
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Supplemental in Support
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/17/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic re sup decl of Reshma Kumar (p)
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/17/17</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Declaration
                                in sup of atty fee's and costs
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/17/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail decl in sup of atty fee's and costs
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/21/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/21/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/21/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/21/17</div>
                        </div>
                        <div>
                            <div>Stipulation
                                &amp; Order - No Fee
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>g
                                re: continuance of request for order re motion to compel
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/21/17</div>
                        </div>
                        <div>
                            <div>Stipulation
                                &amp; Order - No Fee
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>g
                                re: visitation reports
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/21/17</div>
                        </div>
                        <div>
                            <div>DV
                                Continuance
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/31/17</div>
                        </div>
                        <div>
                            <div>DV
                                Continuance
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(p)
                                c/f 7/31/2017 set by judge per dv116
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/31/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/31/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/02/17</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>ex
                                parte *pre-filed*
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/02/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>fl-320
                                to ptr's ex parte req
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/03/17</div>
                        </div>
                        <div>
                            <div>Request
                                for Order (Modification)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(p)
                                modify, c/s s/s, other: imputation of income / terminate s/s to
                                rsp / award support to ptr
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/03/17</div>
                        </div>
                        <div>
                            <div>Declaration:
                                In Support
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>of
                                rfo
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/03/17</div>
                        </div>
                        <div>
                            <div>Memorandum:
                                Points and Authorities
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>in
                                support of rfo
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/03/17</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>of
                                Lisa Trustin
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/03/17</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Income and Expense
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Income
                                and Expense Declaration
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/04/17</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: TRO (Mod. incl Custody and/or Visitation)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>ost/teco
                                granted
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/04/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>RFO</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/04/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>RFO</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/10/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/14/17</div>
                        </div>
                        <div>
                            <div>Memorandum:
                                Points and Authorities
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Memorandum:
                                Points and Authorities
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/14/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail sup briefing-restraining order
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/15/17</div>
                        </div>
                        <div>
                            <div>Correspondence</div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>Received</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/21/17</div>
                        </div>
                        <div>
                            <div>Stipulation
                                and Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>attorney
                                fees
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/22/17</div>
                        </div>
                        <div>
                            <div>Reply
                                Brief
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Petitioner's
                                Reply Brief
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/22/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>PTR's
                                Reply Brief
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/29/17</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>8/10/17</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/29/17</div>
                        </div>
                        <div>
                            <div>Order:
                                Renew Restraining Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar, Daya Baran,</div>
                        </div>
                        <div>
                            <div/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/29/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/05/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>r/o</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/08/17</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: TRO
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>ost
                                grranted, teco denied
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/08/17</div>
                        </div>
                        <div>
                            <div>Memorandum:
                                Points and Authorities
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/11/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>RFO;
                                Temp Order
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/13/17</div>
                        </div>
                        <div>
                            <div>Substitution:
                                Attorney
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>Substitution
                                of Attorney (r)
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/18/17</div>
                        </div>
                        <div>
                            <div>Fee
                                Waiver Application
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/18/17</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/18/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/19/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/19/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/19/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/20/17</div>
                        </div>
                        <div>
                            <div>Fee
                                Waiver Order-Grant
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/20/17</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: TRO
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>ost/teco
                                denied
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/26/17</div>
                        </div>
                        <div>
                            <div>Verified
                                Answer to CCP 170.1-3
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Order
                                Striking Statement of Disqulification w/POS
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/26/17</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Declaration
                                re Long-Cause
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/26/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic - Declaration
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/27/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/28/17</div>
                        </div>
                        <div>
                            <div>FL-NTC
                                OF TRIAL DATE
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/10/17</div>
                        </div>
                        <div>
                            <div>Notice</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Notice
                                of Trial; hrg 12/11/17 @9:00am in D64
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/10/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic; trial notice: hrg 12/11/17 @9:00am in D64
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/12/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/18/17</div>
                        </div>
                        <div>
                            <div>Request
                                for Order (Initial)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Request
                                for Order (Initial)
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/18/17</div>
                        </div>
                        <div>
                            <div>Declaration:
                                In Support
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Declaration:
                                In Support
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/18/17</div>
                        </div>
                        <div>
                            <div>Memorandum:
                                Points and Authorities
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Memorandum:
                                Points and Authorities
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/19/17</div>
                        </div>
                        <div>
                            <div>Order</div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>vacating
                                10/31/17
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/24/17</div>
                        </div>
                        <div>
                            <div>Undocumented
                                Action
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(R)
                                $100.00 fee waived per vaild f/w filed 9/18/17 re: Ntc of
                                Appeal-mag
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/24/17</div>
                        </div>
                        <div>
                            <div>Notice
                                of Appeal - Court of Appeal
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/27/17</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: Other (No Fee)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(P)
                                Amended
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/02/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Personal
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>civil
                                subpoena
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/02/17</div>
                        </div>
                        <div>
                            <div>Acknowledgement
                                of Receipt: Transfer
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Amended
                                RFO re: custody &amp; visitation; RFO re: custody &amp;
                                visitation; P's decl iso RFO re: child custody &amp; visitation
                                orders; Memorandum of points &amp; auth's iso P's rfo re: custody
                                &amp; visitation; Blank resp decl to rfo
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/02/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Electronic
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Amended
                                RFO re: custody &amp; visitation; RFO re: custody &amp;
                                visitation; P's decl iso RFO re: child custody &amp; visitation
                                orders; Memorandum of points &amp; auth's iso P's rfo re: custody
                                &amp; visitation; Blank resp decl to rfo
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/01/17</div>
                        </div>
                        <div>
                            <div>Responsive
                                Declaration to Request for Order
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>for
                                Hrg 12/5/17
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/04/17</div>
                        </div>
                        <div>
                            <div>Declaration:
                                Income and Expense
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Income
                                and Expense Declaration; hearing 12/11/17 9:00am
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/04/17</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service: Mail
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>POS
                                I &amp; E dec; hearing 12/11/17 9:00am
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/21/17</div>
                        </div>
                        <div>
                            <div>Appeal:
                                Clerk's Notice of Appeal
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/26/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/26/17</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/27/17</div>
                        </div>
                        <div>
                            <div>Appeal:
                                Appellant's Default Notice
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/02/18</div>
                        </div>
                        <div>
                            <div>Designation/Transcript
                                on Appeal
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(r)
                                Fee waiver on file- Td
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/12/18</div>
                        </div>
                        <div>
                            <div>Clerks
                                Certificate/Compliance
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/12/18</div>
                        </div>
                        <div>
                            <div>OSC:
                                Contempt
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/12/18</div>
                        </div>
                        <div>
                            <div>Appeal:
                                Appellant's Default Notice
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/16/18</div>
                        </div>
                        <div>
                            <div>Findings
                                &amp; Order After Hearing
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>12/11/17</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/16/18</div>
                        </div>
                        <div>
                            <div>Order:
                                Income Withholding for Support
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/25/18</div>
                        </div>
                        <div>
                            <div>Designation/Transcript
                                on Appeal
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(r)Amended-
                                Fee waiver on file
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/01/18</div>
                        </div>
                        <div>
                            <div>Clerks
                                Certificate/Compliance
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(H045384)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/01/18</div>
                        </div>
                        <div>
                            <div>Appeal:
                                Appellant's Default Notice
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>H045384)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/21/18</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/23/18</div>
                        </div>
                        <div>
                            <div>Notice
                                Regarding Payment of Support - DCSS In
                            </div>
                        </div>
                        <div>
                            <div>County_of Santa Clara,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>03/08/18</div>
                        </div>
                        <div>
                            <div>Notice
                                Regarding Payment of Support - DCSS In
                            </div>
                        </div>
                        <div>
                            <div>County_of Santa Clara,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>03/16/18</div>
                        </div>
                        <div>
                            <div>Appeal:
                                Notice of Appellant's Non-Compliance
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(H045384)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/10/18</div>
                        </div>
                        <div>
                            <div>Designation/Transcript
                                on Appeal
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>Amended</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/12/18</div>
                        </div>
                        <div>
                            <div>Fee
                                Waiver Application
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/12/18</div>
                        </div>
                        <div>
                            <div>Fee
                                Waiver Order-Grant
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/14/18</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: TRO (Mod. incl Custody and/or Visitation)
                            </div>
                        </div>
                        <div>
                            <div>Daya
                                Baran,
                            </div>
                        </div>
                        <div>
                            <div>ost/teo
                                denied
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/26/18</div>
                        </div>
                        <div>
                            <div>Notice
                                of Motion
                            </div>
                        </div>
                        <div>
                            <div>County_of Santa Clara,
                            </div>
                        </div>
                        <div>
                            <div>to
                                determine arrears and set payback on arrears -evc
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/11/18</div>
                        </div>
                        <div>
                            <div>Appeal:
                                Appellant's Default Notice
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(H045384)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/25/18</div>
                        </div>
                        <div>
                            <div>Designation/Transcript on Appeal</div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>2nd Amended</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/25/18</div>
                        </div>
                        <div>
                            <div>Designation/Transcript on Appeal</div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>2nd Amended</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/07/18</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/13/18</div>
                        </div>
                        <div>
                            <div>Short Form Order After Hearing</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/13/18</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/15/18</div>
                        </div>
                        <div>
                            <div>Request for Order: Other (No Fee)</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>mod c/s</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/15/18</div>
                        </div>
                        <div>
                            <div>Request for Order: Other (Fee Applies)</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>mod spousal/partner support</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/15/18</div>
                        </div>
                        <div>
                            <div>Declaration: Income and Expense</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/20/18</div>
                        </div>
                        <div>
                            <div>Request for Order: TRO (Mod. incl Custody and/or Visitation)</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>teo denied pending hearing; re:c/c, c/v</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/22/18</div>
                        </div>
                        <div>
                            <div>Proof of Service: Mail</div>
                        </div>
                        <div>
                            <div>County_of Santa Clara,</div>
                        </div>
                        <div>
                            <div>sfoah (p; r; Natalie Ferro)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/22/18</div>
                        </div>
                        <div>
                            <div>Proof of Service: Mail</div>
                        </div>
                        <div>
                            <div>County_of Santa Clara,</div>
                        </div>
                        <div>
                            <div>rfo (p; Natalie Ferro)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/30/18</div>
                        </div>
                        <div>
                            <div>Responsive Declaration to Request for Order</div>
                        </div>
                        <div>
                            <div>County_of Santa Clara,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/31/18</div>
                        </div>
                        <div>
                            <div>Proof of Service: Mail</div>
                        </div>
                        <div>
                            <div>County_of Santa Clara,</div>
                        </div>
                        <div>
                            <div>resp decl; notice to produce &amp; appear (p; r; Nathlie Lezama)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/10/18</div>
                        </div>
                        <div>
                            <div>Responsive Declaration to Request for Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>HRG 9/12/18; Responsive Declaration to Request for Order</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/10/18</div>
                        </div>
                        <div>
                            <div>Proof of Service: Electronic</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>POS ELEC; HRG 9/12/18; Responsive Declaration to Request for Order</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/10/18</div>
                        </div>
                        <div>
                            <div>Proof of Service: Mail</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Proof of Service: Mail; HRG 9/12/18; Responsive Declaration to Request for Order</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/10/18</div>
                        </div>
                        <div>
                            <div>Appeal: Clerk's Certificate of Compliance</div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(H045384)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/11/18</div>
                        </div>
                        <div>
                            <div>Appeal: Appellant's Default Notice</div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(H045384)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/12/18</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/17/18</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/17/18</div>
                        </div>
                        <div>
                            <div>Short Form Order After Hearing</div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/17/18</div>
                        </div>
                        <div>
                            <div>Declaration: Income and Expense</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/17/18</div>
                        </div>
                        <div>
                            <div>Responsive Declaration to Request for Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/18/18</div>
                        </div>
                        <div>
                            <div>Responsive Declaration to Request for Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>HRG 9/20/18; Responsive Declaration to Request for Order</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/18/18</div>
                        </div>
                        <div>
                            <div>Declaration: In Opposition</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>HRG 9/20/18; P'S Decl In Oppositon to R's RFO</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/24/18</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/24/18</div>
                        </div>
                        <div>
                            <div>Minute
                                Order
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/27/18</div>
                        </div>
                        <div>
                            <div>Proof
                                of Service
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(IPI)
                                Short Form After Hearing filed 9/17/18
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/10/18</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Supplemental Briefing Supporting Petitioner's Opposition to Respondent's Opposition
                                RFO
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/19/18</div>
                        </div>
                        <div>
                            <div>Order</div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>Denying respondent's Req to Modify S/S...</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/05/18</div>
                        </div>
                        <div>
                            <div>Proof of Service: Personal</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>FL-145</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/06/18</div>
                        </div>
                        <div>
                            <div>Order After Hearing</div>
                        </div>
                        <div>
                            <div>County_of Santa Clara,</div>
                        </div>
                        <div>
                            <div>heard on 11/05/18</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/07/18</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/16/18</div>
                        </div>
                        <div>
                            <div>Proof of Service: Mail</div>
                        </div>
                        <div>
                            <div>Santa Clara,</div>
                        </div>
                        <div>
                            <div>OAH</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/28/18</div>
                        </div>
                        <div>
                            <div>Findings &amp; Order After Hearing</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>heard on 9/20/18</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>11/28/18</div>
                        </div>
                        <div>
                            <div>Affidavit</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>of Nathalie Lezama Ferro regarding FOAH</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>12/21/18</div>
                        </div>
                        <div>
                            <div>Appeal:
                                Notice of Appellant's Non-Compliance
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(H045384)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/23/19</div>
                        </div>
                        <div>
                            <div>Request
                                for Order: Other (Fee Applies)
                            </div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(p) move away</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/23/19</div>
                        </div>
                        <div>
                            <div>Declaration: In Support</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/23/19</div>
                        </div>
                        <div>
                            <div>Memorandum: Points and Authorities</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/28/19</div>
                        </div>
                        <div>
                            <div>Fee Waiver Application</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/28/19</div>
                        </div>
                        <div>
                            <div>Fee Waiver Order-Grant</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/29/19</div>
                        </div>
                        <div>
                            <div>Request for Order: TRO</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>02/21/19</div>
                        </div>
                        <div>
                            <div>Remittitur</div>
                        </div>
                        <div>
                        </div>
                        <div>
                            <div>(H045384) Appeal filed 10/24/17 is dismissed</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>03/06/19</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>03/15/19</div>
                        </div>
                        <div>
                            <div>Brief: Trial</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Petitioners trial brief</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>03/15/19</div>
                        </div>
                        <div>
                            <div>Proof of Service: Electronic</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Petitioners trial brief</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>03/19/19</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>04/23/19</div>
                        </div>
                        <div>
                            <div>Request for Order: TRO (Mod. incl Custody and/or Visitation)</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>teo denied re: c/c, c/v</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/03/19</div>
                        </div>
                        <div>
                            <div>Proof of Service: Personal</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>FL300; FL305; FL330</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/06/19</div>
                        </div>
                        <div>
                            <div>Order: Renew Restraining Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>amended 730</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/15/19</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/30/19</div>
                        </div>
                        <div>
                            <div>Order: Renew Restraining Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>2nd amended 730</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/05/19</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Declaration of R's Attorney for need of long cause HRG</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/06/19</div>
                        </div>
                        <div>
                            <div>Responsive Declaration to Request for Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(P)</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/06/19</div>
                        </div>
                        <div>
                            <div>Declaration: In Opposition</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>(P) of PTR in Opposition to RSP s Request to Mod c/c, &amp; c/v</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/11/19</div>
                        </div>
                        <div>
                            <div>Order: Renew Restraining Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>3rd amended order</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/12/19</div>
                        </div>
                        <div>
                            <div>Declaration</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>Notice of non-opposition to request for long cause hearing</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/12/19</div>
                        </div>
                        <div>
                            <div>Opposition/Objections</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>Evidentiary to objections to ptr s responsive declaration</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/13/19</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/13/19</div>
                        </div>
                        <div>
                            <div>Notice: Limited Scope Representation</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>rsp's limit scope rep</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/18/19</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>06/19/19</div>
                        </div>
                        <div>
                            <div>Declaration: Income and Expense</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>Income and Expense Declaration</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>07/16/19</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/07/19</div>
                        </div>
                        <div>
                            <div>Request for Order: Attorney Fees</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>(R) a/f, FC Section 2030 Fees</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/07/19</div>
                        </div>
                        <div>
                            <div>Memorandum: Points and Authorities</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>(R) in Support of RSP s Request for Needs-Based Fees FC2030</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/07/19</div>
                        </div>
                        <div>
                            <div>Declaration: In Support</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>(R) of Julian Hubbard in Support of RSP s Request for Needs-Based Fees FC2030</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/07/19</div>
                        </div>
                        <div>
                            <div>Exhibit List (Party)</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>(R) of RSP in Support of RSP s Request for Needs-Based Fees FC2030</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>08/14/19</div>
                        </div>
                        <div>
                            <div>Proof of Service: Mail</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>Proof of Service: Mail; RFP RE: HRG 9/17/19; MPA ISO R's RFO; Dec; Exhibits</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/12/19</div>
                        </div>
                        <div>
                            <div>Responsive Declaration to Request for Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>exparte resp dec</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/17/19</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/25/19</div>
                        </div>
                        <div>
                            <div>Statement: Settlement Conference</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>Settlement Conference Statement/Trial Briefs</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/25/19</div>
                        </div>
                        <div>
                            <div>Statement: Settlement Conference</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>HRG 9/26/19; Petitioners MSC Statement</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/25/19</div>
                        </div>
                        <div>
                            <div>Proof of Service: Electronic</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Proof of Service: Electronic HRG 9/26/19; Petitioners MSC Statement</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/26/19</div>
                        </div>
                        <div>
                            <div>MinuteOrder</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>09/26/19</div>
                        </div>
                        <div>
                            <div>Stipulation &amp; Order - No Fee</div>
                        </div>
                        <div>
                            <div>Reshma Kumar, Daya Baran,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>10/15/19</div>
                        </div>
                        <div>
                            <div>Substitution: Attorney</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>Substitution of Attorney</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/07/20</div>
                        </div>
                        <div>
                            <div>Minute Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>01/08/20</div>
                        </div>
                        <div>
                            <div>FL-NTC OF FAMILY SETL OFCR CONF</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/08/20</div>
                        </div>
                        <div>
                            <div>Responsive Declaration to Request for Order</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>ex parte *pre-filed*</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/08/20</div>
                        </div>
                        <div>
                            <div>Declaration: In Support</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>atty dec re: a/f</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/08/20</div>
                        </div>
                        <div>
                            <div>Proof of Service: Electronic</div>
                        </div>
                        <div>
                            <div>Reshma Kumar,</div>
                        </div>
                        <div>
                            <div>resp dec; a/f dec</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/19/20</div>
                        </div>
                        <div>
                            <div>Declaration: Income and Expense</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>05/20/20</div>
                        </div>
                        <div>
                            <div>Request for Order: TRO</div>
                        </div>
                        <div>
                            <div>Daya Baran,</div>
                        </div>
                        <div>
                            <div>(r)
                                visitation denied, ost denied, accounting &amp; disbursement of
                                community prop. assets, payment of necessary living expenses from
                                community assets and for reconnection, atty fees &amp; costs
                            </div>
                        </div>
                    </div>
                </TableContent>
            </Container>
        </HomeTableWrap>
    );
};

export default AriannaTable;
