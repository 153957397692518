import styled from "styled-components";

const TopNavWrap = styled.div`
// background-color: #F0F2F5;
background-color: #e4dfe8;
  // text-align: center;
  // padding-top: 18px;
  // padding-bottom: 18px;
  
   p {
    //  margin-top: 24px;
    //  margin-bottom: 18px;
    //  font-size: 16px;
    //  font-weight: 600;
    //  color: #8f3dce;
  //   //letter-spacing: 3px;
  //   //text-transform: uppercase;
  // 
  // a {
  //   :hover, &.active {
  //     color: #000000;
  //   }
}
`

export default TopNavWrap